import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.min";
import { useAuth } from "../auth";

import IconEmail from "@mui/icons-material/AlternateEmailOutlined";
import IconPass from "@mui/icons-material/LockOpenOutlined";
import IconView from "@mui/icons-material/VisibilityOutlined";
import IconForward from "@mui/icons-material/ArrowForward";
import IconSignup from "@mui/icons-material/AssignmentInd";


const SOCIAL = {
  google: `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_API}/auth/google&response_type=code&scope=profile email`,
  facebook: `https://www.facebook.com/v11.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_API}/auth/callback/facebook&response_type=code&scope=email,public_profile`,
  naver: `https://nid.naver.com/oauth2.0/authorize?client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_API}/auth/callback/naver&response_type=code`,
  kakao: `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_API}/auth/callback/kakao&response_type=code`,
};


const SignIn = ({isMobile}) => {
	const { login, loginBy } = useAuth();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [error, setError] = useState(null);

	const closeModal = () => {
		const modal = document.querySelector("#sign-in.modal");
    if (modal) {
      const bootstrapModal = Modal.getInstance(modal);
      if (bootstrapModal) bootstrapModal.hide();
    }
	};

  const dismiss = (e) => {
    if (e.target.closest(".panel") || e.target.closest(".copyright")) return;
		closeModal();
  };

	const fail = (e) => {
		const form=document.querySelector("#sign-in form[name='sign-in']");
		form.classList.add("wiggle");
		setTimeout(()=>form.classList.remove("wiggle"),1000);
		console.log(error);
	}

	const verify = async (e) => {
		const formData = {
			"email":e.target.email.value,
			"password":e.target.password.value
		};
		//console.log(formData);
    e.preventDefault();
    setError(null);
    await login(formData, closeModal, fail); // login 함수 호출
		if (error) { console.log(error); }
  };

	const social = (provider) => {
		const authUrl = SOCIAL[provider];
		if (!authUrl) {
			console.log("Unsupported provider");
			return;
		}

    const width = isMobile ? window.innerWidth : 500;
    const height = isMobile ? window.innerHeight : 600;
    const left = isMobile ? 0 : (window.screen.width / 2) - (width / 2);
    const top = isMobile ? 0 : (window.screen.height / 2) - (height / 2);
    const popup = window.open(authUrl,"Social Login",`width=${width},height=${height},top=${top},left=${left},resizable=no,scrollbars=yes,status=no`);

		if (!popup) {
      console.error("Popup blocked");
      return;
    }

    const handleMessage = (event) => {
			console.log(event.origin);
      if (event.origin !== process.env.REACT_APP_API) {

        console.error("Invalid origin");
        return;
      }

      const { error, token } = event.data;

      if (error) {
        console.error("Login failed");
      } else {
				loginBy(token,closeModal); // login 함수 호출
      }
      //if (popup) popup.close(); // Popup 창 닫기
      window.removeEventListener("message", handleMessage); // 리스너 정리
    };

    // 메시지 리스너 등록
    window.addEventListener("message", handleMessage);

	};

	useEffect(() => {
		const modal = document.getElementById("sign-in");
		const masthead = document.getElementById("masthead")
		const showModal = () => {
			masthead.classList.add("alt");
			setIsModalOpen(true);
			AOS.refresh();
		};

		const hideModal = () => {
			setIsModalOpen(false);
			modal.classList.add("fade");
			masthead.classList.remove("alt");
		};

		modal.addEventListener("show.bs.modal", showModal); // aos 효과 적용
		modal.addEventListener("hide.bs.modal", hideModal);

		return () => {
			modal.removeEventListener("show.bs.modal", showModal);
			modal.removeEventListener("hide.bs.modal", hideModal);
		};
	}, []);

	return (
		<div className={`modal member${isMobile?" mobile":""}`} id="sign-in" data-bs-backdrop="static">
			<div className="modal-dialog modal-fullscreen" role="document">
				{isModalOpen && (
					<div className="modal-content" onClick={dismiss}>
						<div className="modal-body">
							<section className="sign-in">
								<div className="container">
									<div className="panel">
										<h1>코글<small className="text-primary"> - 로그인</small></h1>
										<p className="lead">{isMobile?"":"가상화폐 시장의 최신 소식을 확인하세요"}</p>
										<form method="post" name="sign-in" className="form" data-aos="fade-up" data-aos-delay="400" onSubmit={verify}>
											<fieldset>
												<legend>Please, enter your email and password for login.</legend>
												<ul>
													<li>
														<div className="input-group">
															<span className="input-group-text"><IconEmail />{isMobile?"":"이메일"}</span>
															<input name="email" type="email" className="form-control" minLength="8" required autoComplete="off" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" autoFocus placeholder={isMobile?"이메일":""} />
														</div>
														<div className="input-group">
															<span className="input-group-text"><IconPass />{isMobile?"":"비밀번호"}</span>
															<input name="password" type="password" className="form-control" minLength="4" required autoComplete="off" placeholder={isMobile?"비밀번호":""} />
															<button type="button" className="input-group-text" data-toggle="visible"><IconView /></button>
														</div>
													</li>
													<li><button type="submit" className="btn btn-danger"><IconForward style={{fontSize:"4.5rem",fontWeight:"bold"}} /></button></li>
												</ul>
											</fieldset>
										</form>
										<ul style={{flexWrap:"wrap"}}>
											<li><button className="social btn" type="button" onClick={() => social("google")}><i className="google"></i> 구글</button></li>
											<li><button className="social btn" type="button" onClick={() => social("facebook")}><i className="facebook"></i> 페이스북</button></li>
											<li><button className="social btn" type="button" onClick={() => social("naver")}><i className="naver"></i> 네이버</button></li>
											<li><button className="social btn" type="button" onClick={() => social("kakao")}><i className="kakao"></i> 카카오</button></li>
											<li><button className="social btn" type="button" onClick={() => social("apple")}><i className="apple"></i> 애플</button></li>
										</ul>
										<div>
											<span className="text-primary">아직 회원이 아니신가요?</span>
											<button type="button" className="btn text-warning" data-bs-toggle="modal" data-bs-target="#sign-up"><IconSignup />계정 만들기</button>
										</div>
									</div>
									<div className="hero" data-aos="fade-up" data-aos-delay="300"><img src="/i/whale.webp" alt="" className="img-fluid" /></div>
								</div>
								<div className="slant">
								</div>
							</section>
							<section className="social">
								<ul>
									<li><button className="vendor facebook" type="button"></button></li>
									<li><button className="vendor twitter" type="button"></button></li>
									<li><button className="vendor google" type="button"></button></li>
									<li><button className="vendor kakao" type="button"></button></li>
									<li><button className="vendor naver" type="button"></button></li>
								</ul>
							</section>
						</div>
						<div className="modal-footer">
							<div className="container">
								<div className="copyright">
									<p>Copyright ©<time dateTime="2023">2023</time>All rights reserved. Made with <img src="/i/heart.svg" className="heart" alt="heart" /> by <button type="button" data-bs-dismiss="modal">코글</button></p>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
export default SignIn;
