import React from "react";
import useMore from "../more"; // Custom Hook import
import axios from "axios";

const Test = ({ apiUrl }) => {
  // 데이터 로드 함수 정의
  const fetchData = async (page) => {
    const response = await axios.get(`https://api.cogle.io/popular/forum?page=${page}`);
    const { data: list, more } = response.data; // API에서 데이터와 '더 보기' 여부 반환
    return { list, more };
  };

  // useMore Custom Hook 사용
  const { data, loadMore, hasMore, loading } = useMore(fetchData);

  return (
    <section className="popular forum">
      <h1>커뮤니티 인기글</h1>
      <ul>
        {data.map((item, index) => (
          <li key={index} className="forum-item">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </li>
        ))}
      </ul>
      {!loading && hasMore && (
        <button onClick={loadMore} className="load-more-btn">
          더 보기
        </button>
      )}
      {loading && <p>로딩 중...</p>}
      {!hasMore && <p>더 이상 데이터가 없습니다.</p>}
    </section>
  );
};

export default Test;

