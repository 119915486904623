import React from "react";

export const Fng = ({ data }) => {
	return (
		<div className="chart fng" data-fng={data.value} style={{backgroundImage:`url(data:image/svg+xml;base64,${data.svg})`}}>
			<p>{data.value}</p>
			<p>{data.class}</p>
		</div>
	);
};

export const Alt = ({ value }) => {
	return (
		<div className="alt" data-alt-season={value}>
			<h4>{value}<small>/100</small></h4>
			<div className="legend"><span>비트코인 시즌</span><span>알트코인 시즌</span></div>
			<ul className="chart"><li></li><li></li><li></li><li></li></ul>
			<div className="on"><span style={{left:`${value}%`}}></span></div>
		</div>
	);
};

export const Dominance = ({ data }) => {
	data.btc=data.btc.toLocaleString(undefined,{minimumFractionDigits:1,maximumFractionDigits:1});
	data.eth=data.eth.toLocaleString(undefined,{minimumFractionDigits:1,maximumFractionDigits:1});
	data.alt=data.alt.toLocaleString(undefined,{minimumFractionDigits:1,maximumFractionDigits:1});
	return (
		<div className="dominance" data-dominance={data.btc}>
			<ul className="legend">
				<li className="btc"><h4>비트코인</h4><p>{data.btc}</p></li>
				<li className="eth"><h4>이더리움</h4><p>{data.eth}</p></li>
				<li className="alt"><h4>알트코인</h4><p>{data.alt}</p></li>
			</ul>
			<ul className="chart"><li style={{width:`${data.btc}%`}}></li><li style={{width:`${data.eth}%`}}></li><li></li></ul>
		</div>
	);
};

