import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Vendor from "../../vendor";
import { ago, ellipsis, loadCss } from "../../site";
import useMore from "../../more";
import { More } from "../../component/pagination";

import IconAt from "@mui/icons-material/AccessTime";
import IconRight from "@mui/icons-material/ChevronRight";

const News = ({ sidebar }) => {
  const { domain } = useParams();
  const [stylized, stylize] = useState(false);
  const domainRef = useRef(domain);
	const loaded = useRef(false);
  const api = process.env.REACT_APP_API;

  const fetchData = async (page) => {
    const response = await axios.get(`${api}/popular/news`,{params:domain?{vendor:domain,page}:{page}});
    if (response.data.error) {
      throw new Error("데이터 로드 실패");
    }
    return {list:response.data.data, more:response.data.more};
  };

  const { data, loadMore, hasMore, loading, reset, error, clearError } = useMore(fetchData);

  useEffect(() => {
		return loadCss("/css/news.css",()=>stylize(true));
  }, []);

  useEffect(() => {
    if (domainRef.current !== domain) {
      reset();
      domainRef.current = domain;
			loaded.current = false;
    }
  }, [domain, reset]);

	useEffect(() => {
		if (!loaded.current) { // 로딩 중이 아닐 때만 실행
			loadMore();
			loaded.current=true;
		}
	}, [loadMore]);


  if (!stylized) return null;
  const vendor = Vendor.news[domain] || { name: null };

	const List=({e,rank})=>{
		const at=ago(e.at);
		return (
				<li key={`news-${rank}`}>
					<article className="hentry card">
					<h3 className="card-header entry-title"><a href={e.url} data-id={e.id} target="_blank" rel="noopener noreferrer"><span className="badge rank">{rank+1}</span>{e.title}</a></h3>
					<div className="card-body">
						<ul>
							<li><span className="vendor">{e.vendor.name}</span><IconAt /><time dateTime={e.at} className="ago">{at}</time></li>
							<li className="description">
								<a href={e.url} data-id={e.id} target="_blank" rel="noopener noreferrer">{e.thumbnail?(<img src={e.thumbnail} alt="" />):(
									e.video&&(<iframe src={e.video} title={e.title}></iframe>)
								)}</a>
								<a href={e.url} data-id={e.id} target="_blank" rel="noopener noreferrer">
								{ellipsis(e.description,sidebar.mobile?96:240)}
								{(e.author&&e.author!==e.vendor.name)&&(<span className="author">{e.author}</span>)}
								</a>
							</li>
						</ul>
					</div>
				</article>
				</li>
		);
	};

  return (
    <section className={`popular news${sidebar.mobile?" mobile":""}`}>
			<Helmet>
        <title>코글 - 인기뉴스</title>
      </Helmet>
			<h1>인기뉴스{vendor.name && (<small><IconRight />{vendor.name}</small>)}</h1>
			{(data?.length > 0) && (
			<ul>{data.map((e, rank) => (<List key={rank} e={e} rank={rank} />))}</ul>
			)}
      {error && <More load={()=>false} options={{text:"데이터를 불러오는 중 오류가 발생했습니다."}} />}
      {!loading && hasMore && (<More load={()=>{clearError();loadMore();}} options={{text:"더 보기"}} />)}
      {loading &&  <More load={()=>false} options={{text:"Loading..."}} />}
      {!hasMore && !error && <div>- 끝 -</div>}
    </section>
  );
};

export default News;
