import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { ago, loadCss } from "../../site";
import { More } from "../../component/pagination";
import IconTo from "@mui/icons-material/ArrowForwardIos";

const Whale = ({ sidebar }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stylized, stylize] = useState(false);
  const [limit, setLimit] = useState(20);
  const api = process.env.REACT_APP_API;

  useEffect(() => {
    return loadCss("/css/insight.css", () => stylize(true));
  }, []);

  useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${api}/insight/whale`, {params:{limit:limit}});
				if (response.data.error === false) {
					console.log(limit);
					//setData(limit===0 ? response.data.data : [...data, ...response.data.data]); // 모든 데이터를 가져오거나 기존 데이터에 추가
					//setData(prevData => limit === 0 ? response.data.data : [...prevData, ...response.data.data]);
					setData(response.data.data);
				} else {
					console.log(response);
					throw new Error("데이터 로드 실패");
				}
			} catch (err) {
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};
    fetchData(); // 초기 데이터 로드
  }, [limit, api]);


  if (!stylized) return null;
  if (loading) return <div className="loading"><span className="loader">Loading…</span></div>;
  if (error) return <p>데이터를 불러오는 중 오류가 발생했습니다: {error}</p>;

  return (
    <section className={`insight whale${sidebar.mobile?" mobile":""}`}>
      <Helmet>
        <title>Whale Transactions - Insight</title>
        <meta name="description" content="암호화폐 대규모 트랜잭션 정보를 실시간으로 확인하세요. 비트코인(BTC), 이더리움(ETH) 등 주요 블록체인의 고래 움직임을 추적합니다." />
        <meta name="keywords" content="암호화폐, 트랜잭션, 고래, 고래 입출금, 비트코인, 이더리움, Whale Transactions, 블록체인" />
      </Helmet>
			<h1>고래 입출금</h1>
      <ul>
        {data.map((t,n) => (
        <li className="card" key={t.hash}>
					<h3 className="card-header">
						<span className="crypto coin" data-symbol={t.symbol.toLowerCase()}>{t.symbol}</span>
						<small>{sidebar.mobile?"":t.type}</small>
						<time dateTime={t.at}>{ago(t.at)}<small>전</small></time>
					</h3>
					<ul className="card-body">
						<li className="transfer">
							<span className="sender crypto" data-exchange={t.senderAka?t.senderAka:t.sender&&t.sender.toLowerCase()}>{t.sender&&t.sender}</span>
							<span className="to"><IconTo /></span>
							<span className="receiver crypto" data-exchange={t.receiverAka?t.receiverAka:t.receiver&&t.receiver.toLowerCase()}>{t.receiver??""}</span>
						</li>
					{sidebar.mobile?(
						<li><span><strong>{t.amount.toLocaleString()}</strong><small>{t.symbol}</small> <strong>{t.usd.toLocaleString()}</strong><small>USD</small></span></li>
					) : (
						<li><span>Amount: </span><span><strong>{t.amount.toLocaleString()}</strong><small>{t.symbol}</small> &nbsp; ({t.usd.toLocaleString()}<small>USD</small>)</span></li>
					)}
					</ul>
				</li>
        ))}
      </ul>
      {limit === 20 && <More load={() => setLimit(0)} options={{ text: "더 보기" }} />}
    </section>
  );
};

export default Whale;
