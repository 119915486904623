import React from "react";
import { Link } from "react-router-dom";
import IconUser from "@mui/icons-material/AccountCircleTwoTone";
import { useAuth } from "../auth";
import { avatar } from "../site";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.min";

export const Welcome = ({className, isMobile, size, navi}) => {
  const { auth, logout } = useAuth();
	const nav= (fn) => {
    const e=document.querySelector(".modal.show");
		if (e) {
      const modal=Modal.getInstance(e);
			if (modal) modal.hide();
    }; 
		fn();
  };

  if (!auth) {
    return (
      <button type="button" className={className} data-bs-toggle="modal" data-bs-target="#sign-in"><IconUser /> 로그인</button>
    );
  }

	const icon = avatar(auth.profile.avatar, {size:size} );
	if (isMobile) {
		return (
			<div className="profile">
				<button type="button" className={`${className?className:""}`}>{icon} {auth.profile.email}</button>
			</div>
		);
	}
  return (
		<div className="dropdown">
			<button type="button" className={`sign-out dropdown-toggle${className?" "+className:""}`} data-bs-toggle="dropdown">{icon}</button>
			<ul className="dropdown-menu">
				<li><Link to="/profile" className="dropdown-item" onClick={()=>{nav(()=>navi("/profile"))}}>내프로파일</Link></li>
				<li><button type="button" className="dropdown-item" onClick={logout}>로그아웃</button></li>
			</ul>
		</div>
  );
};

