import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { ago, ellipsis, loadCss } from "../../site";
import useMore from "../../more";
import { More } from "../../component/pagination";

import IconAt from "@mui/icons-material/AccessTime";
import IconBookmark from "@mui/icons-material/PlaylistAdd";
import IconPlace from "@mui/icons-material/Place";
import IconFollow from "@mui/icons-material/People";
import IconTweet from "@mui/icons-material/MarkUnreadChatAlt";
import IconFriend from "@mui/icons-material/PersonAdd";
import IconTrans from "@mui/icons-material/GTranslate";
import IconFavor from "@mui/icons-material/PersonAdd";

const Twitter = ({ sidebar }) => {
  const { type } = useParams();
  const [stylized, stylize] = useState(false);
  const typeRef = useRef(type);
  const loaded = useRef(false);
  const [tab, setTab] = useState(type || "tweet");
  const navigate = useNavigate();
  const api = process.env.REACT_APP_API;

  const fetchData = async (page) => {
    const response = await axios.get(`${api}/popular/twitter`, { params: { type, page } });
    if (response.data.error) throw new Error("데이터 로드 실패");
    return { list: response.data.data, more: response.data.more };
  };

  const { data, loadMore, hasMore, loading, reset, error, clearError } = useMore(fetchData);

  const nav = (tab) => {
    setTab(tab);
    reset();
    navigate(`/popular/twitter/${tab}`);
  };

  useEffect(() => {
    return loadCss("/css/twitter.css", () => stylize(true));
  }, []);

  useEffect(() => {
    if (typeRef.current !== type) {
      reset(); // 데이터 초기화
      typeRef.current = type; // 현재 type 갱신
      loaded.current = false; // 초기화 상태 리셋
    }
  }, [type, reset]);

  // 초기 데이터 로드
  useEffect(() => {
    if (!loaded.current && !loading) {
      loadMore();
      loaded.current = true;
    }
  }, [loading, loadMore]);

  if (!stylized) return null;

	const List=({e,rank})=>{
		const url=`https://www.twitter.com/${e.channel}`;
		const name=e.name?e.name:e.channel;
		if (type==="channel") return (
			<li>
				<article className="hentry card">
					<h3 className="card-header entry-title"><span className="badge rank">{rank}</span>
					{e.thumbnail&&(<a href={e.url} target="_blank" rel="noopener noreferrer" className="thumbnail"><img src={e.thumbnail} alt="" /></a>)}
					<a href={url} target="_blank" rel="noopener noreferrer">{name}</a></h3>
					<div className="card-body">
						<div className="entry-content">
							<div className="thumbnail">{e.thumbnail&&(<a href={url} target="_blank" rel="noopener noreferrer"><img src={e.thumbnail} alt="" /></a>)}</div>
							<div className="description">
								<div><a href={url} target="_blank" rel="noopener noreferrer">{ellipsis(e.description,160)}</a></div>
								<ul>
									<li><IconPlace /><span>{e.location}</span></li>
									<li><IconAt /><time dateTime={e.created_at}>{ago(e.created_at)}</time></li>
									<li><IconFollow /><span>{e.follow.toLocaleString()}</span><small className="text-muted">팔로우</small></li>
									<li><IconTweet /><span>{e.tweet.toLocaleString}</span><small className="text-muted">트윗</small></li>
									<li><IconFriend /><span>{e.friend.toLocaleString}</span><small className="text-muted">팔로우 중</small></li>
									<li><button type="button" className="btn btn-sm btn-light rounded-pill m-0 p-0 ps-1 ms-2 pe-2" style={{border:"1px solid silver"}}><IconBookmark /> <small>저장</small></button></li>
								</ul>
								{e.last&&(
								<div className="last">
									<a href={`https://twitter.com/${e.last.url}`} target="_blank" rel="noopener noreferrer">
										<small className="text-muted">{e.last.content} <small className="text-warning"><time dateTime={e.last.created_at}>{ago(e.last.created_at)}</time></small></small>
									</a>
								</div>
								)}
						 	</div>
						</div>
					</div>
				</article>

			</li>
		);
		else return (
			<li>
				<article className="hentry card">
					<h3 className="card-header entry-title">
						<span className="badge rank">{rank}</span>
						<a href={url} target="_blank" className="thumbnail" rel="noopener noreferrer"><img src={e.thumbnail} alt={e.channel} rel="noopener noreferrer" /></a>
						<a href={url} target="_blank" rel="noopener noreferrer">{e.name}</a>
						<button type="button" data-translate="ko" className="btn btn-sm btn-light rounded-pill m-0 p-0 ps-1 ms-2 pe-2" style={{border:"1px solid silver"}} data-text=""><IconTrans /></button>
					</h3>
					<div className="card-body">
						<div className="entry-content">
							<div className="thumbnail"><a href={url} target="_blank" rel="noopener noreferrer"><img src={e.thumbnail} alt={e.channel} /></a></div>
							<div className="description">
								<div><a href={url} target="_blank" rel="noopener noreferrer">{e.content}</a></div>
								<ul>
									<li><IconAt /><time dateTime={e.created_at}>{ago(e.created_at)}</time><small className="text-muted">전</small></li>
									<li><IconFollow /><span>{e.retweet.toLocaleString()}</span><small className="text-muted">리트윗</small></li>
									<li><IconTweet /><span>{e.reply.toLocaleString()}</span><small className="text-muted">댓글</small></li>
									<li><IconFavor /><span>{e.bookmark.toLocaleString()}</span><small className="text-muted">북마크</small></li>
									<li><button type="button" className="btn btn-sm btn-light rounded-pill m-0 p-0 ps-1 ms-2 pe-2" style={{border:"1px solid silver"}}><IconBookmark /> <small>저장</small></button></li>
								</ul>
							</div>
						</div>
						{(e.media?.length>0)&&(
						<ul className="media">                                                                                                                                                                                                                          
							{e.media.map((m,n)=>(
								<React.Fragment key={`m-${n}`}>
								{m.type==="photo"&&(<li key={`media-${n}`}><img src={m.url} alt="" /></li>)}
								{(m.type==="video"||m.type==="animation")&&(
									<li key={`m-${n}`}>
										<video width="640" height="360" controls>
											{m.video.map((source,s)=>(
												<source key={`s-${s}`} src={source.url} type={source.content_type} />
											))}
										 Your browser does not support the video tag.
										</video>
									</li>
									)}
								</React.Fragment>
							))}
						</ul>
						)}
					</div>
				</article>
			</li>
		);
	};

  return (
    <section className={`popular twitter${sidebar.mobile?" mobile":""}`}>
			<Helmet>
        <title>코글 - 코인 트위터</title>
      </Helmet>
			<h1>코인 트위터</h1>
			<ul className="nav nav-tabs">
        <li className="nav-item"><button className={`nav-link${tab==="tweet"?" active":""}`} onClick={()=>nav("tweet")}>최신 트윗</button></li>
				<li className="nav-item"><button className={`nav-link${tab==="channel"?" active":""}`} onClick={()=>nav("channel")}>인기 채널</button></li>
      </ul>
			{(data?.length > 0) && (<ul className={tab}>{data.map((e, rank) => (<List key={rank} e={e} rank={rank+1} />))}</ul>)}
      {error && <More load={()=>false} options={{text:"데이터를 불러오는 중 오류가 발생했습니다."}} />}
      {!loading && hasMore && (<More load={()=>{clearError();loadMore();}} options={{text:"더 보기"}} />)}
      {loading &&  <More load={()=>false} options={{text:"Loading..."}} />}
      {!hasMore && !error && <div>- 끝 -</div>}
    </section>
  );
};

export default Twitter;
