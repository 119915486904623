import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { loadCss } from "../../site";

const Kimp = ({ sidebar }) => {
  const { exchange } = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState(exchange||"upbit");
  const [stylized, stylize] = useState(false);
  const [data, setData] = useState({});
  const [last, setLast] = useState({});
  const [loading, setLoading] = useState(false);
  const api = process.env.REACT_APP_API;
	const kimpRef = useRef(data.kimp || {});

  useEffect(() => {
    kimpRef.current = data.list;
  }, [data]);

  const nav = (tab) => {
    setTab(tab);
    navigate(`/insight/kimp/${tab}`);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response=await axios.get(`${api}/insight/kimp`,{params:{exchange:tab}});
      const r=response.data;
			if (kimpRef.current && Object.keys(kimpRef.current).length) {
				console.log("Previous kimpRef:", kimpRef.current);
				setLast({ ...kimpRef.current });
      }
      setData(r.data||{});
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [api, tab]);

  useEffect(() => {
    fetchData();
		/*
    const interval = setInterval(() => {
      fetchData();
    }, 300000);
    return () => clearInterval(interval);
		*/
  }, [fetchData]);

  useEffect(() => {
		return loadCss("/css/insight.css",()=>stylize(true));
  }, []);

  const getDelta = (now, last) => {
    if (last === undefined) return "none";
    return now > last ? "positive" : now < last ? "negative" : "neutral";
  };

  if (!stylized) return null;


  return (
    <section className={`insight kimp${sidebar.mobile?" mobile":""}`}>
			<Helmet>
        <title>코글 - 김프</title>
      </Helmet>
      <h1>김프</h1>
      <ul className="nav nav-tabs">
        <li className="nav-item"><button className={`nav-link crypto${tab==="upbit"?" active":""}`} data-exchange="upbit" onClick={() => nav("upbit")}>Upbit</button></li>
				<li className="nav-item"><button className={`nav-link crypto${tab==="bithumb"?" active":""}`} data-exchange="bithumb" onClick={() => nav("bithumb")}>Bithumb</button></li>
				<li className="nav-item"><button className={`nav-link crypto${tab==="coinone"?" active":""}`} data-exchange="coinone" onClick={() => nav("coinone")}>Coinone</button></li>
      </ul>
			{data.rates&&(
			<div className="rate krw">
				<div>
					<button type="button" className="btn py-0 dropdown-toggle" data-bs-toggle="dropdown" data-to="krw">환율</button>
					<div className="dropdown-menu dropdown-menu-end px-2" style={{minWidth:"24rem"}}>
						<ul className="list-group list-group-flush">
						{Object.entries(data.rates).map(([key, value]) => (
							<li key={key} className="list-group-item">1 <span className="token">{key}</span>: <span className="krw">{value.toLocaleString()}</span></li>
						))}
						</ul>
					</div>
				</div>
				<div className="apply"></div>
			</div>
			)}
      {sidebar.mobile ? (
        <>
				{loading ? (
				<div className="loading"><span className="loader">Loading…</span></div>
				) : data.kimp ? (
					<ul>
						{Object.entries(data.kimp).map(([token, exchanges]) => (
							<li key={token} className="card">
								<h3 className="card-header crypto coin" data-symbol={token.toLowerCase()}>{token}</h3>
								<ul className="card-body">
								{data.exchange.map((exchange) => {
								const now = exchanges[exchange] || 0; // 해당 거래소 값이 없으면 0으로 처리
								const lastValue = last.kimp?.[token]?.[exchange] !== undefined
									? last.kimp[token][exchange]
									: undefined;
								const deltaClass = getDelta(now, lastValue);
								return (
									<>
									{now!==0 && (
									<li key={exchange} className={`delta ${deltaClass}`}><span className="crypto" data-exchange={exchange.toLowerCase()}>{exchange}</span><span>{now !== 0 ? now.toFixed(2) : ""}</span></li>
									)}
									</>
								);
								})}
								</ul>
							</li>
						))}
					</ul>
				) : (
					<div>No data available</div>
				)}
        </>
      ) : (
        <>
				{loading ? (
				<div className="loading"><span className="loader">Loading…</span></div>
				) : data.kimp ? (
				<table className="table">
					<thead>
						<tr>
							<th>Symbol</th>
							{data.exchange.map((exchange) => (
							<th key={exchange}><span className="crypto" data-exchange={exchange.toLowerCase()}>{exchange}</span></th>
							))}
						</tr>
					</thead>
					<tbody>
						{Object.entries(data.kimp).map(([token, exchanges]) => (
						<tr key={token}>
							<td><strong className="crypto coin" data-symbol={token.toLowerCase()}>{token}</strong></td>
							{data.exchange.map((exchange) => {
								const now = exchanges[exchange] || 0; // 해당 거래소 값이 없으면 0으로 처리
								const lastValue = last.kimp?.[token]?.[exchange] !== undefined
									? last.kimp[token][exchange]
									: undefined;
								const deltaClass = getDelta(now, lastValue);
								return (
							<td key={exchange} className={`delta ${deltaClass}`}> {now !== 0 ? now.toFixed(2) : ""}</td>
								);
							})}
						</tr>
						))}
					</tbody>
				</table>
				) : (
					<div>No data available</div>
				)}
        </>
      )}
    </section>
  );
};

export default Kimp;
