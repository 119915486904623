import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const Profile = ({ sidebar, navi }) => {
  useEffect(() => {
		navi("/profile");
  }, [navi]);
  return (
    <section className={`popular forum${sidebar.mobile?" mobile":""}`}>
			<Helmet>
        <title>코글 - 내 프로파일</title>
      </Helmet>
			<h1>내 프로파일 </h1>
    </section>
  );
};

export default Profile;
