import { useState, useCallback } from "react";

const useMore = (fetchData) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const loadMore = useCallback(async () => {
    if (loading || !hasMore) {
			console.log("loadMore 호출 방지: 이미 로딩 중 또는 더 이상 데이터 없음");
			return; // 중복 호출 방지
		}
    setLoading(true);
    setError(false);

    try {
      const { list, more } = await fetchData(page);
      setData((prevData) => [...prevData, ...list]);
      setHasMore(more);
      setPage((prevPage) => prevPage + 1);
    } catch (err) {
      console.error("Error loading data:", err);
      setError(true);
    } finally {
      setLoading(false); // 비동기 작업 종료 후 `loading` 해제
    }
  }, [fetchData, page, hasMore, loading]);

  const reset = useCallback(() => {
    setData([]);
    setPage(1);
    setHasMore(true);
    setLoading(false);
    setError(false);
  }, []);

  const clearError = useCallback(() => setError(false), []);

  return { data, loadMore, hasMore, loading, reset, error, clearError };
};

export default useMore;
