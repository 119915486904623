import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { ago, bytes, loadCss } from "../site";
import { useAuth } from "../auth";
import useMore from "../more";
import { More } from "../component/pagination";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.min";
import Add from "../component/notice-add";

import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

import IconDown from "@mui/icons-material/Download";

const Notice = ({ sidebar }) => {
  const { auth } = useAuth();
  const [stylized, stylize] = useState(false);
  const api = process.env.REACT_APP_API;
  const loaded = useRef(false);

  const fetchData = async (page) => {
    const response = await axios.get(`${api}/notice/`);
    if (response.data.error) {
      throw new Error("데이터 로드 실패");
    }
    return { list: response.data.data??[], more: response.data.more };
  };

  const { data, loadMore, hasMore, loading, reset, error, clearError } = useMore(fetchData);

	const content = (e) => {
    if (!e) return null;

    const rawContent = JSON.parse(e);
    const contentState = convertFromRaw(rawContent);
    const html = stateToHTML(contentState);
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

	const attachment = (e) => {
    if (e.length === 0) return null;
    return (
      <div>
        <h3>첨부파일</h3>
        <ul className="attachment">
          {e.map((file, index) => (
            <li key={index} className="file">
							<a href={file.path} download={file.name} target="_blank" rel="noopener noreferrer" ><IconDown className="text-primary" /><span>{file.name}</span><small className="size">{bytes(file.size)}</small></a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

	const onClose = (changed=true) => {
		if (changed) {
			loaded.current = false;
			reset();
			loadMore();
		}
		const modal = document.querySelector("#add-notice.modal");
    if (modal) {
      const bootstrapModal = Modal.getInstance(modal);
      if (bootstrapModal) bootstrapModal.hide();
    }
	}

  useEffect(() => {
    return loadCss("/css/notice.css", () => stylize(true));
  }, []);

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      loadMore();
    }
  }, [loadMore]);


  if (!stylized) return null;

  return (
    <section className={`notice${sidebar.mobile?" mobile":""}`}>
			<Helmet>
        <title>코글 - 공지사항</title>
      </Helmet>
			<h1>공지사항 {auth?.isStaff && (<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add-notice">새 글쓰기</button>)}</h1>
      {data?.length > 0 && (
			<ul id="notice" className="list-group list-grup-flush accordion">
				{data.map((e)=>(
				<li key={`notice-${e.id}`}  className="list-group-item p-0 accordion-item">
					<article className="">
						<h3 className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={`#notice-${e.id}`}><span>{e.title}</span><time dateTime={e.updated_at} className="text-muted">{ago(e.updated_at)}<small>전</small></time></h3>
						<div id={`notice-${e.id}`} className="collapse" data-bs-parent="#notice">
							<div className="content">
							{content(e.content)}
							{attachment(e.attachment??[])}
							</div>
						</div>
					</article>
				</li>
				))}
				
			</ul>
      )}
      {error && <More load={()=>false} options={{text:"데이터를 불러오는 중 오류가 발생했습니다."}} />}
      {!loading && hasMore && (<More load={()=>{clearError();loadMore();}} options={{text:"더 보기"}} />)}
      {loading &&  <More load={()=>false} options={{text:"Loading..."}} />}
      {!hasMore && !error && <></>}
			<Add isMobile={sidebar.mobile} onClose={onClose} auth={auth} />
    </section>
  );
};

export default Notice;
