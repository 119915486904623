/* sidebar accordion nav */
import React, { createContext, useContext, useState } from "react";

const NavContext = createContext();

export const Nav = ({ children }) => {
  const [nav, setNav] = useState(()=>{
    const saved = sessionStorage.getItem("nav");
    return saved ? JSON.parse(saved):[];
	});

  return (
    <NavContext.Provider value={{ nav, setNav }}>
      {children}
    </NavContext.Provider>
  );
};

export const useNav = () => {
  const context = useContext(NavContext);
  if (!context) {
    throw new Error("useNav must be used within a NavProvider");
  }
  return context;
};
