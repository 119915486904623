import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { loadCss } from "../../site";

const Exchange = ({ sidebar }) => {
  const { type } = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState(type||"spot");
  const [stylized, stylize] = useState(false);
  const [data, setData] = useState({});
  const [column, setColumn] = useState({});
  const [loading, setLoading] = useState(false);
  const api = process.env.REACT_APP_API;

  const nav = (tab) => {
    setTab(tab);
    navigate(`/insight/exchange/${tab}`);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response=await axios.get(`${api}/insight/exchange`,{params:{type:tab}});
      const r=response.data;
      setColumn(r.column||{});
      setData(r.data||{});
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [api, tab]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
		return loadCss("/css/insight.css",()=>stylize(true));
  }, []);

  if (!stylized) return null;

  return (
    <section className={`insight exchange${sidebar.mobile?" mobile":""}`}>
			<Helmet>
        <title>코글 - 거래소 {tab} </title>
      </Helmet>
      <h1>거래소</h1>
      <ul className="nav nav-tabs">
        <li className="nav-item"><button className={`nav-link crypto${tab==="spot"?" active":""}`} onClick={() => nav("spot")}>현물</button></li>
				<li className="nav-item"><button className={`nav-link crypto${tab==="derivative"?" active":""}`} onClick={() => nav("derivative")}>파생{sidebar.mobile||"상품"}</button></li>
				<li className="nav-item"><button className={`nav-link crypto${tab==="dex-spot"?" active":""}`} onClick={() => nav("dex-spot")}>DEX (현물)</button></li>
				<li className="nav-item"><button className={`nav-link crypto${tab==="dex-derivative"?" active":""}`} onClick={() => nav("dex-derivative")}>DEX (파생{sidebar.mobile||"상품"})</button></li>
      </ul>
      {sidebar.mobile ? (
        <>
				{loading ? (
				<div className="loading"><span className="loader">Loading…</span></div>
				) : column?.length>0 ? (
					<ul>
						{data.map((e) => (
							<li key={e.exchange.slug} className="card">
								<h3 className="card-header crypto" data-exchange={e.exchange.aka}>{e.exchange.name}</h3>
								<ul className="card-body">
								{e.value.map((v,n) => {
								const label = column[n];
								return (
									<>
									<li key={`{e.exchange.slug}-{n}`}><span>{label}</span><span>{v}</span></li>
									</>
								);
								})}
								</ul>
							</li>
						))}
					</ul>
				) : (
					<div>No data available</div>
				)}
        </>
      ) : (
        <>
				{loading ? (
				<div className="loading"><span className="loader">Loading…</span></div>
				) : column?.length>0 ? (
				<table className="table">
					<thead>
						<tr>
							<th>{tab==="spot"?"Exchange":"Name"}</th>
							{column.map((name) => (
							<th key={name}><span className="crypto">{name}</span></th>
							))}
						</tr>
					</thead>
					<tbody>
						{data.map((e) => (
						<tr key={e.exchange.slug}>
							<td><strong className="crypto" data-exchange={e.exchange.aka}>{e.exchange.name}</strong></td>
							{e.value.map((v,i) => (
							<td key={`${e.exchange.slug}-${i}`}>{v}</td>
							))}
						</tr>
						))}
					</tbody>
				</table>
				) : (
					<div>No data available</div>
				)}
        </>
      )}
    </section>
  );
};

export default Exchange;
