import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.min";
import { useAuth } from "../auth";

import IconEmail from "@mui/icons-material/AlternateEmailOutlined";
import IconPass from "@mui/icons-material/LockOpenOutlined";
import IconView from "@mui/icons-material/VisibilityOutlined";
import IconNick from "@mui/icons-material/Face";


const SignUp = ({isMobile}) => {
	const { login } = useAuth();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [error, setError] = useState(null);

	const closeModal = () => {
		const modal = document.querySelector("#sign-up.modal");
    if (modal) {
      const bootstrapModal = Modal.getInstance(modal);
      if (bootstrapModal) bootstrapModal.hide();
    }
	};

  const dismiss = (e) => {
    if (e.target.closest(".panel") || e.target.closest(".copyright")) return;
		closeModal();
  };

	const fail = (e) => {
		const form=document.querySelector("#sign-up form[name='sign-up']");
		form.classList.add("wiggle");
		setTimeout(()=>form.classList.remove("wiggle"),1000);
		console.log(error);
	}


	useEffect(() => {
		const modal = document.getElementById("sign-up");
		const masthead = document.getElementById("masthead")
		const showModal = () => {
			masthead.classList.add("alt");
			setIsModalOpen(true);
			AOS.refresh();
		};

		const hideModal = () => {
			setIsModalOpen(false);
			modal.classList.add("fade");
			masthead.classList.remove("alt");
		};

		modal.addEventListener("show.bs.modal", showModal);
		modal.addEventListener("hide.bs.modal", hideModal);

		return () => {
			modal.removeEventListener("show.bs.modal", showModal);
			modal.removeEventListener("hide.bs.modal", hideModal);
		};
	}, []);

	return (
		<div className={`modal member${isMobile?" mobile":""}`} id="sign-up" data-bs-backdrop="static">
			<div className="modal-dialog modal-fullscreen" role="document">
				{isModalOpen && (
					<div className="modal-content" onClick={dismiss}>
						<div className="modal-body">
							<section className="sign-up">
								<div className="container">
									<div className="panel">
										<h1>코글<small className="text-primary"> - 회원가입</small></h1>
										<p className="lead">{isMobile?"":"가상화폐 시장의 최신 소식을 확인하세요"}</p>
										<form method="post" name="sign-up" action="javascript:" className="form" data-aos="fade-up" data-aos-delay="400">
											<fieldset>
												<legend>Please, enter your email and password for login.</legend>
												<ul>
													<li className="input-group">
														<span className="input-group-text"><IconEmail />{isMobile?"":"이메일"}</span>
														<input name="email" type="email" className="form-control" minLength="8" required autoComplete="off" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder={isMobile?"이메일":""} />
													</li>
													<li className="input-group">
														<span className="input-group-text"><IconNick />{isMobile?"":"닉네임"}</span>
														<input name="nick" type="text" className="form-control" minLength="3" required autoComplete="off" placeholder={isMobile?"닉네임":""} />
													</li>
													<li className="input-group">
														<span className="input-group-text"><IconPass />{isMobile?"":"비밀번호"}</span>
														<input name="password" type="password" className="form-control" minLength="4" required autoComplete="off" placeholder={isMobile?"비밀번호":""} />
														<button type="button" className="input-group-text" data-toggle="visible"><IconView /></button>
													</li>
													<li className="input-group">
														<span className="input-group-text"><IconPass />{isMobile?"":"비번확인"}</span>
														<input name="password-again" type="password" className="form-control" minLength="4" required autoComplete="off" placeholder={isMobile?"비밀확인":""} />
														<button type="button" className="input-group-text" data-toggle="visible"><IconView /></button>
													</li>
													<li className="form-check">
														<label className="form-check-label">
															<input name="agreement" className="form-check-input" type="checkbox" value="agree" />
															<strong className="text-primary">이용 약관</strong> 및 <strong className="text-primary">개인정보 처리방침</strong>에 동의 합니다
														</label>
													</li>
													<li className="submit"><button type="submit" className="btn btn-primary form-control">계정 만들기</button></li>
												</ul>
											</fieldset>
											<div className="divider">
												<hr />
												<em>또는</em>
											</div>
											<ul className="list-group list-group-flush">
												<li><button className="list-group-item social" type="button"><i className="google"></i> 구글<small className="text-muted">로 계정 만들기</small></button></li>
												<li><button className="list-group-item social" type="button"><i className="facebook"></i> 페이스북<small className="text-muted">으로 계정 만들기</small></button></li>
												<li><button className="list-group-item social" type="button"><i className="naver"></i> 네이버<small className="text-muted">로 계정 만들기</small></button></li>
												<li><button className="list-group-item social" type="button"><i className="kakao"></i> 카카오<small className="text-muted">로 계정 만들기</small></button></li>
											</ul>
										</form>
									</div>
									<div className="hero" data-aos="fade-up" data-aos-delay="300"><img src="/i/whale.webp" alt="" className="img-fluid" /></div>
								</div>
								<div className="slant">
								</div>
							</section>
							<section className="social">
								<ul>
									<li><button className="vendor facebook" type="button"></button></li>
									<li><button className="vendor twitter" type="button"></button></li>
									<li><button className="vendor google" type="button"></button></li>
									<li><button className="vendor kakao" type="button"></button></li>
									<li><button className="vendor naver" type="button"></button></li>
								</ul>
							</section>
						</div>
						<div className="modal-footer">
							<div className="container">
								<div className="copyright">
									<p>Copyright ©<time dateTime="2023">2023</time>All rights reserved. Made with <img src="/i/heart.svg" className="heart" alt="heart" /> by <button type="button" data-bs-dismiss="modal">코글</button></p>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
export default SignUp;
