import React, { useState, useEffect } from "react";
import io from "socket.io-client";

const api =process.env.REACT_APP_API
const socket = io(api, { autoConnect: false });

const Chat = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isConnected, setIsConnected] = useState(false); // WebSocket 연결 상태
  const [error, setError] = useState(null); // 에러 메시지

  useEffect(() => {
    try {
      // WebSocket 연결 시도
      socket.connect();

      socket.on("connect", () => {
        setIsConnected(true); // 연결 성공
        setError(null); // 에러 초기화
      });

      socket.on("chat message", (msg) => {
        setMessages((prev) => [...prev, msg]);
      });

      socket.on("connect_error", (err) => {
        setError("WebSocket 연결 중 오류가 발생했습니다."); // 연결 오류 처리
        console.error("WebSocket 연결 실패:", err.message);
        setIsConnected(false);
      });
    } catch (err) {
      setError("WebSocket 연결 중 문제가 발생했습니다.");
      console.error(err);
    }

    return () => {
      // 컴포넌트 언마운트 시 WebSocket 연결 해제 및 이벤트 제거
      socket.off("chat message");
      socket.disconnect();
    };
  }, []);

  const send = (e) => {
    e.preventDefault(); // 폼 제출 시 기본 동작 방지
    if (message.trim() !== "" && isConnected) {
      socket.emit("chat message", message); // 메시지 전송
      setMessage(""); // 입력창 초기화
    }
  };

  return (
    <section className="chat card" style={{display:"none"}}>
      <div className="card-header">
        <h1 style={{ fontSize: "1rem" }}>채팅</h1>
      </div>
      <div className="card-body">
        {!isConnected && error && ( // 연결되지 않은 경우 에러 메시지 표시
          <div className="alert alert-danger">{error}</div>
        )}
        <div className="messages">
          <ul>
            {messages.map((msg, index) => (
              <li key={index} className="message">
                {msg}
              </li>
            ))}
          </ul>
        </div>
        <form onSubmit={send} className="form">
          <div className="input-group">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="메시지를 입력하세요"
              className="form-control"
              disabled={!isConnected} // 연결되지 않았을 때 입력 비활성화
            />
            <button type="submit" className="input-group-text" disabled={!isConnected}>
              전송
            </button>
          </div>
        </form>
      </div>
      <div className="card-footer">
        <button className="btn">채팅하려면 로그인</button>
      </div>
    </section>
  );
};

export default Chat;
