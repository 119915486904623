import React, { createContext, useState, useEffect } from "react";

export const ThemeContext = createContext();

export const Theme = ({ children }) => {
  const initialize = () => {
    const saved = localStorage.getItem("theme");
    if (saved) return saved;
    return "system";
  };

  const [theme, setTheme] = useState(initialize);
  const [resolaved, resolve] = useState(theme);

  const os = () => {
    const dark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    resolve(dark ? "dark" : "light");
  };

  useEffect(() => {
    if (theme === "system") os();
    else resolve(theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  useEffect(() => {
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    const osTheme = (e) => {
      if (theme === "system") resolve(e.matches ? "dark" : "light");
    };

    matchMedia.addEventListener("change", osTheme);
    return () => {
      matchMedia.removeEventListener("change", osTheme);
    };
  }, [theme]);

  useEffect(() => {
    document.documentElement.setAttribute("data-bs-theme", resolaved);
  }, [resolaved]);

  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, resolaved, setTheme: toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
