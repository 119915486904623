import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useMobile } from "./mobile";
import { useNav } from "./nav";

import "./style/typography.css";
import "./style/cogle.scss";
import "./style/global.css";
import "./style/member.css";
import "./style/alternative.css";
import "aos/dist/aos.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import AOS from "aos";

import Header from "./component/header";
import Sidebar from "./component/sidebar";
import HeadUp from "./component/head-up";

import Chat from "./component/chat";
import SignIn from "./component/sign-in";
import SignUp from "./component/sign-up";

import Front from "./page/front";
import NotFound from "./page/40x";
import Profile from "./page/profile";

import Forum from "./page/popular/forum";
import News from "./page/popular/news";
import Youtube from "./page/popular/youtube";
import Twitter from "./page/popular/twitter";

import Coin from "./page/insight/coin";
import Exchange from "./page/insight/exchange";
import Future from "./page/insight/future";
import Position from "./page/insight/position";
import Kimp from "./page/insight/kimp";
import Chart from "./page/insight/chart";
import Whale from "./page/insight/whale";
import Notice from "./page/notice";


import Keyword from "./page/etc/keyword";
import Test from "./page/test";

const App = () => {

	const { isMobile } = useMobile();
	const [sidebar, setSidebar] = useState(() => {
		const saved = localStorage.getItem("sidebar");
		const initialSidebar = saved ? JSON.parse(saved) : {};
		return {loaded:false, collapsed:initialSidebar.collapsed ?? isMobile, mobile: isMobile, ...initialSidebar};
	});

	const { nav, setNav } = useNav();

	const toggleSidebar = useCallback(() => {
		setSidebar((prev) => ({ ...prev, collapsed: !prev.collapsed }));
	}, []);

	const sidebarLoaded = useCallback(() => {
		setSidebar((prev) => ({ ...prev, loaded: true }));
	}, []);

	const hideSidebar = useCallback(() => {
		setSidebar((prev) => ({ ...prev, collapsed: true }));
	}, []);

	const navi = useCallback((path) => {
		setNav(path.split("/").filter(Boolean));
		if (sidebar.mobile) hideSidebar();
	},[setNav, sidebar.mobile, hideSidebar]);

	useEffect(() => {
		localStorage.setItem("sidebar", JSON.stringify(sidebar));
	}, [sidebar]);

	useEffect(() => {
		sessionStorage.setItem("nav", JSON.stringify(nav));
	}, [nav]);

	useEffect(() => {
		if (sidebar.mobile !== isMobile) {
			setSidebar((prev) => ({ ...prev, mobile: isMobile }));
		}
	}, [isMobile, sidebar.mobile]);

	useEffect(() => {
		AOS.init({ duration: 1000, once: true });
	}, []);

	return (
			<Router>
				<div className={`app ${isMobile ? "mobile" : ""} ${ sidebar.collapsed ? "collapsed" : "expanded" }`}>
					<Header toggleSidebar={toggleSidebar} navi={navi} />
					<div className="container">
						<Sidebar sidebar={sidebar} loadComplete={sidebarLoaded} hideSidebar={hideSidebar} navi={navi} />
						<main id="main">
							<Routes>
								<Route path="/robots.txt" element={null} />
								<Route path="/" element={<Front navi={navi} />} />
								<Route path="/profile" element={<Profile sidebar={sidebar} navi={navi} />} />
								<Route path="/academy" element={<NotFound />} />
								<Route path="/popular" element={<NotFound />} />
								<Route path="/popular/forum/:domain?" element={<Forum sidebar={sidebar} />} />
								<Route path="/popular/news/:domain?" element={<News sidebar={sidebar} />} />
								<Route path="/popular/youtube" element={<Youtube sidebar={sidebar} />} />
								<Route path="/popular/twitter/:type?" element={<Twitter sidebar={sidebar} />} />
								<Route path="/community" element={<NotFound />} />
								<Route path="/insight" element={<NotFound />} />
								<Route path="/insight/coin/:p?" element={<Coin sidebar={sidebar} />} />
								<Route path="/insight/exchange/:type?" element={<Exchange sidebar={sidebar} />} />
								<Route path="/insight/dominance" element={<Chart sidebar={sidebar} />} />
								<Route path="/insight/future/:type?" element={<Future sidebar={sidebar} />} />
								<Route path="/insight/kimp/:exchange?" element={<Kimp sidebar={sidebar} />} />
								<Route path="/insight/position" element={<Position sidebar={sidebar} />} />
								<Route path="/insight/whale" element={<Whale sidebar={sidebar} />} />
								<Route path="/notice" element={<Notice sidebar={sidebar} />} />
								<Route path="/etc" element={<NotFound />} />
								<Route path="/etc/keyword" element={<Keyword sidebar={sidebar} />} />
								<Route path="/test" element={<Test />} />
								<Route path="*" element={<NotFound />} />
							</Routes>
							<footer></footer>
						</main>
						<aside id="aside">
							<div className="container">
								<Chat />
							</div>
						</aside>
					</div>
				</div>
				<div id="off-canvas">
					<HeadUp />
					<SignIn isMobile={sidebar.mobile} />
					<SignUp isMobile={sidebar.mobile} />
				</div>
			</Router>
	);
};

export default App;
