import React, { createContext, useContext, useState } from "react";
const api = process.env.REACT_APP_API;

const AuthContext = createContext();

export const Auth = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const token = localStorage.getItem("token");
		if (token) {
			const profile = JSON.parse(atob(token.split(".")[1]))?.profile;
			const isStaff = profile.is_staff??false;
			return { token, profile, isStaff }
		}
    return null;
  });

  // 로그인 처리 (handleSubmit 로직 포함)
  const login = async (formData, onSuccess, onError) => {
    try {
      const response = await fetch(`${api}/member/sign-in`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      if (!response.ok) throw new Error("로그인 실패. 이메일 또는 비밀번호를 확인하세요.");
      const data = await response.json();
			if (data.error) {
				console.log(data.error);
        throw new Error(data.error);
			} else if (data.token) {
        const profile = JSON.parse(atob(data.token.split(".")[1])).profile; // JWT 디코딩
        localStorage.setItem("token", data.token);
        setAuth({token:data.token,profile:profile,isStaff:profile.is_staff??false});
				if (onSuccess) onSuccess();
      } else {
        throw new Error("서버에서 유효한 토큰을 받지 못했습니다.");
      }
    } catch (error) {
      console.error("로그인 에러:", error);
      if (onError) onError(error.message); // 에러 콜백 실행
    }
  };


  const loginBy = (token, onSuccess) => {
		if (token) {
			localStorage.setItem("token", token);
      const profile = JSON.parse(atob(token.split(".")[1])).profile; // JWT 디코딩
			setAuth({token:token,profile:profile,isStaff:profile.is_staff??false});
			if (onSuccess) onSuccess();
		} 
  };

  const logout = () => {
    localStorage.removeItem("token");
    setAuth(null);
  };

  return (
    <AuthContext.Provider value={{ auth, login, loginBy, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
