import React,{ useState, useRef } from "react";
export const Search = ({expanded}) => {
	const [query, setQuery] = useState("");
	const queryRef = useRef(null);
	const [isSearchExpanded, setIsSearchExpanded] = useState(expanded);
	const handleSearchExpand = () => { setIsSearchExpanded(true); };
	/* const handleSearch = (event) => { setQuery(event.target.value); }; */
	const handleSearchBlur = () => { if (!expanded && !query) setIsSearchExpanded(false);};
	const handleSearchReset = () => { setQuery("");queryRef.current.focus(); };
	const handleSearchChange = (event) => { setQuery(event.target.value); };
	const handleSearchSubmit = (event) => {
		event.preventDefault();
		alert("검색 구상 중…");
		console.log(`Search query: ${query}`); // 검색 기능 예시로 alert 표시
		setQuery(""); // 검색 후 입력 필드 초기화
		if (!expanded) setIsSearchExpanded(false);
	};

	return (
		<form onSubmit={handleSearchSubmit} className={`form search ${isSearchExpanded ? "expanded" : ""}`}>
			<input ref={queryRef} type="text" name="q" value={query} onFocus={handleSearchExpand} onBlur={handleSearchBlur} onChange={handleSearchChange} className={`form-control ${!query ? "prompt" : ""}`} placeholder="   " />
			<button type="button" className="btn reset" onClick={handleSearchReset}>❌</button>
			<button type="submit" className="btn" onClick={handleSearchSubmit}>🔍</button>
		</form>
	);
};
