import React,{ useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useNav } from "../nav";
import { useAuth } from "../auth";
import { ThemeContext } from "../theme";
import { Search } from "./search";
import { Welcome } from "./welcome";

import vendorData from "../asset/vendor.json";

const Sidebar = ({ sidebar, loadComplete, hideSidebar, navi }) => {
	const [vendor, setVendor] = useState(null);
  const { auth, logout } = useAuth();

	const { theme, setTheme } = useContext(ThemeContext);
	const changeTheme = (selected) => { setTheme(selected); };
	const { nav } = useNav();

	useEffect(() => {
		if (vendorData) {
			setVendor(vendorData);
			loadComplete();
		}
		return ()=>{setVendor(null)};
	},[loadComplete]);

	//useEffect(() => { console.log(nav); },[nav]);

	if (!vendor) { return <div className="loading"><span className="loader">Loading…</span></div>; }
	const Nav=()=>{
		return(
			<div className="container">
				{sidebar.mobile&&(
					<ul>
						<li><Welcome className="btn" isMobile={sidebar.mobile} size={30} /></li>
						<li><Search expanded={true} /></li>
					</ul>
				)}
				<ul id="side-nav" className="accordion accordion-flush nav">
					<li className="nav-item"><Link to="/" onClick={()=>navi("/")} className={`nav-link icon${nav.length===0?" on":""}`} title="코글"><i className="nav-icon home"></i><span>{sidebar.collapsed?"홈":"코글"}</span></Link></li>
					<li className="nav-item"><Link to="/academy" onClick={()=>navi("/academy")} className={`nav-link icon${nav[0]==="academy"?" on":""}`} title="코인 아카데미"><i className="nav-icon academy"></i><span>{sidebar.collapsed?"아카데미":"코인 아카데미"}</span></Link></li>

					<li className="nav-item dropend">
						<div className="nav">
							<Link to="/popular" onClick={()=>navi("/popular")} className={`nav-link icon${nav[0]==="popular"?" on":""}`} title="코인 이슈랭킹"><i className="nav-icon popular"></i><span>{sidebar.collapsed?"이슈":"코인 이슈랭킹"}</span></Link>
							<button type="button" className={`accordion-button ${nav[0]==="popular"?"show":"collapsed"}`} data-bs-toggle="collapse" data-bs-target="#nav-popular"></button>
						</div>
						<ul id="nav-popular" className={`accordion collapse${sidebar.collapsed?" dropdown-menu":""}${(!sidebar.collapsed&&nav[0])==="popular"?" show":""}`} data-bs-parent="#side-nav">
							<li className="nav-item dropend">
								<div className="nav">
									<Link to="/popular/forum" onClick={()=>navi("/popular/forum")} className={`nav-link${nav[1]==="forum"?" on":""}`}>커뮤니티</Link>
									<button type="button" className={`accordion-button ${nav[1]==="forum"?"show":"collapsed"}`} data-bs-toggle="collapse" data-bs-target="#nav-popular-forum"></button>
								</div>
								<ul id="nav-popular-forum" className={`accordion collapse${sidebar.collapsed?" dropdown-menu":""}${(!sidebar.collapsed&&nav[1])==="forum"?" show":""}`} data-bs-parent="#nav-popular">
									{Object.entries(vendorData.forum).map(([key, value]) => ((!value.disabled) && (
										<li className="nav-item" key={key}>
											<Link to={`/popular/forum/${key}`} onClick={()=>navi(`/popular/forum/${key}`)} className={`nav-link${nav[2]===key?" on":""}`}>{value.name}</Link>
										</li>
									)))}
								</ul>
							</li>
							<li className="nav-item dropend">
								<div className="nav">
									<Link to="/popular/news" onClick={()=>navi("/popular/news")} className={`nav-link${nav[1]==="news"?" on":""}`}>코인 뉴스</Link>
									<button type="button" className={`accordion-button ${nav[1]==="news"?"show":"collapsed"}`} data-bs-toggle="collapse" data-bs-target="#nav-popular-news"></button>
								</div>
								<ul id="nav-popular-news" className={`accordion collapse${sidebar.collapsed?" dropdown-menu":""}${(!sidebar.collapsed&&nav[1])==="news"?" show":""}`} data-bs-parent="#nav-popular">
									{Object.entries(vendorData.news).map(([key, value]) => ((!value.disabled) && (
										<li className="nav-item" key={key}>
											<Link to={`/popular/news/${key}`} onClick={()=>navi(`/popular/news/${key}`)} className={`nav-link${nav[2]===key?" on":""}`}>{value.name}</Link>
										</li>
									)))}
								</ul>
							</li>
							<li className="nav-item"><Link to="/popular/youtube" onClick={()=>navi("/popular/youtube")} className={`nav-link icon${nav[1]==="youtube"?" on":""}`} title="코인 유튜브"><i className="nav-icon yt"></i>코인 유튜브</Link></li>
							<li className="nav-item"><Link to="/popular/twitter" onClick={()=>navi("/popular/twitter")} className={`nav-link icon${nav[1]==="twitter"?" on":""}`} title="코인 트위터"><i className="nav-icon twitter"></i>코인 트위터</Link></li>
							<li className="nav-item"><Link to="/popular/channel" onClick={()=>navi("/popular/channel")} className={`nav-link${nav[1]==="channel"?" on":""}`} title="코인 채널, 채팅방">코인 채널, 채팅방</Link></li>
						</ul>
					</li>

					<li className="nav-item dropend">
						<div className="nav">
							<Link to="/insight" onClick={()=>navi("/insight")} className={`nav-link icon${nav[0]==="insight"?" on":""}`} title="온체인 분석"><i className="nav-icon app"></i><span>{sidebar.collapsed?"온체인":"온체인 분석"}</span></Link>
							<button type="button" className={`accordion-button ${nav[0]==="insight"?"show":"collapsed"}`} data-bs-toggle="collapse" data-bs-target="#nav-insight"></button>
						</div>
						<ul id="nav-insight" className={`accordion collapse${sidebar.collapsed?" dropdown-menu":""}${(!sidebar.collapsed&&nav[0])==="insight"?" show":""}`} data-bs-parent="#side-nav">
							<li className="nav-item"><Link to="/insight/coin" onClick={()=>navi("/insight/coin")} className={`nav-link${nav[1]==="coin"?" on":""}`} title="코인 순위">코인 순위</Link></li>
							<li className="nav-item"><Link to="/insight/exchange" onClick={()=>navi("/insight/exchange")} className={`nav-link${nav[1]==="exchange"?" on":""}`} title="코인 거래소">코인 거래소</Link></li>
							<li className="nav-item"><Link to="/insight/dominance" onClick={()=>navi("/insight/dominance")} className={`nav-link${nav[1]==="dominance"?" on":""}`} title="코인 도미넌스">코인 도미넌스</Link></li>
							<li className="nav-item"><Link to="/insight/future" onClick={()=>navi("/insight/future")} className={`nav-link${nav[1]==="future"?" on":""}`} title="펀딩 비율">펀딩 비율</Link></li>
							<li className="nav-item"><Link to="/insight/kimp" onClick={()=>navi("/insight/kimp")} className={`nav-link${nav[1]==="kimp"?" on":""}`} title="김치프리미엄">김치프리미엄</Link></li>
							<li className="nav-item"><Link to="/insight/position" onClick={()=>navi("/insight/position")} className={`nav-link${nav[1]==="position"?" on":""}`} title="네임드 포지션">네임드 포지션</Link></li>
							<li className="nav-item"><Link to="/insight/whale" onClick={()=>navi("/insight/whale")} className={`nav-link${nav[1]==="whale"?" on":""}`} title="고래 입출금">고래 입출금</Link></li>
							<li className="nav-item"><Link to="/insight/etc" onClick={()=>navi("/insight/etc")} className={`nav-link${nav[1]==="etc"?" on":""}`} title="유틸리티 바로가기">유틸리티 바로가기</Link></li>
						</ul>
					</li>

					<li className="nav-item dropend">
						<div className="nav">
							<Link to="/forum" onClick={()=>navi("/forum")} className={`nav-link icon${nav[0]==="forum"?" on":""}`} title="커뮤니티"><i className="nav-icon community"></i><span>커뮤니티</span></Link>
							<button type="button" className={`accordion-button ${nav[0]==="forum"?"show":"collapsed"}`} data-bs-toggle="collapse" data-bs-target="#nav-community"></button>
						</div>
						<ul id="nav-community" className={`accordion collapse${sidebar.collapsed?" dropdown-menu":""}${(!sidebar.collapsed&&nav[0])==="forum"?" show":""}`} data-bs-parent="#side-nav">
							<li className="nav-item"><Link to="/notice" onClick={()=>navi("/forum/notice")} className={`nav-link${nav[1]==="notice"?" on":""}`} title="공지사항">공지사항</Link></li>
							<li className="nav-item"><Link to="/coummunity" onClick={()=>navi("/forum/community")} className={`nav-link${nav[1]==="community"?" on":""}`} title="자유게시판">자유게시판</Link></li>
							<li className="nav-item"><Link to="/flex" onClick={()=>navi("/forum/flex")} className={`nav-link${nav[1]==="flex"?" on":""}`} title="수익 인증">수익 인증</Link></li>
							<li className="nav-item"><Link to="/meme" onClick={()=>navi("/forum/meme")} className={`nav-link${nav[1]==="meme"?" on":""}`} title="코인 짤방">코인 짤방</Link></li>
							<li className="nav-item"><Link to="/guru" onClick={()=>navi("/forum/guru")} className={`nav-link${nav[1]==="guru"?" on":""}`} title="고수 라운지">고수 라운지</Link></li>
						</ul>
					</li>

					<li className="nav-item dropend">
						<div className="nav">
							<Link to="/etc" onClick={()=>navi("/etc")} className={`nav-link icon${nav[0]==="etc"?" on":""}`} title="심심풀이"><i className="nav-icon extra"></i><span>심심풀이</span></Link>
							<button type="button" className={`accordion-button ${nav[0]==="etc"?"show":"collapsed"}`} data-bs-toggle="collapse" data-bs-target="#nav-etc"></button>
						</div>
						<ul id="nav-etc" className={`accordion collapse${sidebar.collapsed?" dropdown-menu":""}${(!sidebar.collapsed&&nav[0])==="etc"?" show":""}`} data-bs-parent="#side-nav">
							<li className="nav-item"><Link to="/etc/keyword" onClick={()=>navi("/etc/keyword")} className={`nav-link${nav[1]==="keyword"?" on":""}`} title="실시간 급상승 검색어">실시간 급상승 검색어</Link></li>
							<li className="nav-item"><Link to="/etc/humor" onClick={()=>navi("/etc/humor")} className={`nav-link${nav[1]==="humor"?" on":""}`} title="유머,이슈">유머,이슈</Link></li>
						</ul>
					</li>
				</ul>

				{sidebar.mobile&&(
				<div className="footer">
					{auth&&(<button type="button" className="btn" onClick={logout}>로그아웃</button>)}
					<ul className="theme">
						<li><button className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><span className={`theme-icon ${theme}`}></span>Theme</button>
							<ul className="dropdown-menu dropdown-menu-end theme-switcher">
								<li><button className={`dropdown-item${theme==="light"?" on":""}`} onClick={() => changeTheme("light")}><span className="theme-icon light"></span>라이트</button></li>
								<li><button className={`dropdown-item${theme==="dark"?" on":""}`} onClick={() => changeTheme("dark")}><span className="theme-icon dark"></span>다크</button></li>
								<li><button className={`dropdown-item${theme==="system"?" on":""}`} onClick={() => changeTheme("system")}><span className="theme-icon system"></span>시스템</button></li>
							</ul>
						</li>
					</ul>
				</div>
				)}
			</div>
		);
	};

	return (
		<>
		<nav id="sidebar" className={`${sidebar.collapsed?"collapsed":"expanded"}${sidebar.mobile?" mobile":""}`}>
			<Nav />
		</nav>
		{(sidebar.mobile)&&(<div className={`backdrop${sidebar.collapsed?"":" show"}`} onClick={hideSidebar}></div>)}
		</>
	);
};

export default Sidebar;
