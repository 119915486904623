import React, { useState } from "react";
import { Editor, convertToRaw } from "draft-js";
import useDraft from "../draft";
import axios from "axios";

import IconDelete from "@mui/icons-material/Delete";
import IconInsert from "@mui/icons-material/PostAdd";
import IconAttach from "@mui/icons-material/AttachFile";

const Add = ({ isMobile, auth, onClose }) => {
  const api = process.env.REACT_APP_API;
	const { state, update, insert, files, upload, remove, cancel, reset, render } = useDraft(api,"n");
  const [title, setTitle] = useState("");

	const submit = async (event) => {
    event.preventDefault();
    const content = JSON.stringify(convertToRaw(state.getCurrentContent()));

    const formData = new FormData();
    formData.append("member_id", auth.profile.id ?? 0);
    formData.append("is_staff", auth.isStaff ?? false);
    formData.append("title", title);
    formData.append("content", content);
    formData.append("attachment", JSON.stringify(files));

    try {
      const response = await axios.post(`${api}/notice/add`, formData);
      if (response.data.error) {
        alert("공지사항 등록 실패");
      } else {
				setTitle("");
				reset();
        onClose(true);
      }
    } catch (error) {
      console.error("폼 제출 오류:", error);
      alert("오류가 발생했습니다.");
    }
  };

  const undo = () => {
    cancel();
		reset();
    onClose(false);
  };

  return (
		<div className="modal" id="add-notice" data-bs-backdrop="static">
			<div className="modal-dialog modal-lg" role="document">
				<form className="modal-content form" onSubmit={submit}>
          <div className="modal-header">
						<h4 className="modal-title">새 공지사항</h4>
						<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
					</div>
					<div className="modal-body">
						<input type="text" name="title" className="form-control" placeholder="제목을 입력하세요" value={title} required onChange={(e) => setTitle(e.target.value)} />
						<div className="editor form-control">
							<Editor editorState={state} onChange={update} blockRendererFn={render} />
						</div>
						<div className="browse">
							<button className="btn btn-sm btn-primary"><IconAttach style={{fontSize:"1.1rem"}}/>파일 첨부</button>
							<input type="file" multiple onChange={upload} />
						</div>
						{files.length > 0 && (
						<ul className="attachment">
							{files.map((file, index) => (
							<li key={index} className="file">
								<div className="ellipsis">{file.name}</div>
								<div className="size">{(file.size / 1024).toFixed(2)} KB</div>
								<button type="button" className="btn btn-sm text-danger" onClick={() => remove(index)}><IconDelete style={{fontSize:"1.1rem"}} /><small>삭제</small></button>
								{(file.type.startsWith("image/") || file.type.startsWith("video/")) && (
								<button type="button" className="btn btn-sm text-primary" onClick={() => insert(file.path, file.type)}><IconInsert style={{fontSize:"1.1rem"}} /><small>내용에 추가</small></button>)}
							</li>
							))}
						</ul>
            )}
					</div>
					<div className="modal-footer">
						<div className="input-group input-group-sm">
						<input type="reset" className="btn btn-secondary px-4" data-bs-dismiss="modal" value="닫기" onClick={undo} />
						<button type="submit" className="btn btn-success px-4">저장하기</button>
						</div>
					</div>
        </form>
			</div>
		</div>
  );
};

export default Add;
