import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { Pagination } from "../../component/pagination";
import { formatNumber, loadCss } from "../../site";

const Coin = ({ sidebar }) => {
  const { p } = useParams();
  const page = p ? parseInt(p, 10) : 1;
  const navigate = useNavigate();

  const [data, setData] = useState([]); // 코인 데이터
  const [error, setError] = useState(null); // 에러 상태
  const [loaded, setLoaded] = useState(false); // 로딩 상태
  const [stylized, stylize] = useState(false); // 로딩 상태
  const [total, setTotal] = useState({ count: 0, page: 0 }); // 전체 페이지 수
  const api = process.env.REACT_APP_API;

  useEffect(() => {
		return loadCss("/css/insight.css",()=>stylize(true));
  }, []);

  // 데이터 로드
  useEffect(() => {
    const fetchData = async () => {
      setLoaded(false);
      setError(null); // 에러 초기화
			setData([]);
      try {
				const response = await axios.get(`${api}/insight/coin`,{params:{page}});
        //const response = await axios.get(`${api}/insight/coin?page=${page}`);
        if (!response.data.error) {
          setData(response.data.data);
          setTotal({ count: response.data.total, page: response.data.pages });
					
        } else {
          throw new Error("데이터 로드 실패");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoaded(true);
      }
    };
    fetchData();
  }, [api,page]);

  // 페이지 이동
  const load = (n) => {
		const to=(n>1)?`+${n}`:"";
    navigate(`/insight/coin/${to}`);
  };

	if (!stylized) return null;
  if (error) return <div className="alert alert-danger">데이터를 불러오는 중 오류가 발생했습니다: {error}</div>;

  const List = ({ mobile, data }) => {
    if (mobile) {
      return (
        <ul className="mobile">
          {data.map((coin, rank) => (
            <li>
              <div className="card" style={{ marginBottom: "0.25rem" }}>
                <h3 className="card-header">
                  <span className="rank badge">{(page - 1) * 100 + rank + 1}</span>
                  <strong className="crypto coin" data-symbol={coin.symbol.toLowerCase()} key={coin.symbol}>{coin.name}</strong>
									<strong className="symbol">{coin.symbol}</strong>
                </h3>
                <dl className="card-body">
                  <div><dt>가격 <small className="text-muted">＄</small></dt><dd>{formatNumber(coin.usd.price)}</dd></div>
                  <div><dt>유통량 </dt><dd>{formatNumber(coin.supply)}</dd></div>
									{/*<div><dt>시가총액 <small className="text-muted">＄</small></dt><dd>{formatNumber(coin.usd.cap)}</dd></div>
                  <div><dt>역대최고가 <small className="text-muted">＄</small></dt><dd>{formatNumber(coin.ath)}</dd></div>*/}
                </dl>
              </div>
            </li>
          ))}
        </ul>
      );
    } else {
      return (
        <table className="table table-sm table-hover">
          <thead>
            <tr>
              <th scope="col" className="text-center">#</th>
              <th scope="col">이름</th>
              <th scope="col">심볼</th>
              <th scope="col" className="text-end">가격<small className="text-muted">＄</small></th>
              <th scope="col" className="text-end">유통량</th>
              <th scope="col" className="text-end">시가총액<small className="text-muted">＄</small></th>
              <th scope="col" className="text-end">역대최고가<small className="text-muted">＄</small></th>
            </tr>
          </thead>
          <tbody>
            {data.map((coin, rank) => (
              <tr key={coin.symbol}>
                <td className="text-center"><small className="rank">{(page - 1) * 100 + rank + 1}</small></td>
                <td><strong className="crypto coin" data-symbol={coin.symbol.toLowerCase()}>{coin.name}</strong></td>
                <td className="text-end">{coin.symbol}</td>
                <td className="text-end">{formatNumber(coin.usd.price)}</td>
                <td className="text-end">{formatNumber(coin.supply)}</td>
                <td className="text-end">{formatNumber(coin.usd.cap)}</td>
                <td className="text-end">{formatNumber(coin.ath)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  };

	if (!loaded) return <div className="loading"><span className="loader">Loading…</span></div>;

  return (
    <section className={`insight coin rank${sidebar.mobile?" mobile":""}`}>
			<Helmet>
        <title>코글 - 코인순위</title>
      </Helmet>
      <h1>코인순위</h1>
      {data?.length > 0 && <List mobile={sidebar.mobile} data={data} />}
      {loaded && <Pagination load={load} page={page} total={total.page} options={{prefix:"+",tab:sidebar.mobile?1:2}} />}
    </section>
  );
};

export default Coin;
