import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { More } from "../../component/pagination";
import { loadCss } from "../../site";

// Card 컴포넌트
const Card = ({ symbol, item, flashes }) => (
  <div className="card">
    <h3 className="card-header">
      <span className="crypto coin" data-symbol={symbol.toLowerCase()}>
        {symbol}
      </span>
    </h3>
    <ul className="card-body">
      {Object.entries(item).map(([x, value]) => {
        const flash = flashes.current[`${symbol}-${x}`] || "";
        const delta = flashes.current[`${symbol}-${x}-delta`] || null;
				const cn = delta > 0 ? "positive" : delta < 0 ? "negative" : "neutral";
				const pv = flashes.current[`${symbol}-${x}-prev`] || null;
				
        return (
          <li key={`${symbol}-${x}`} className={`${cn} ${flash}`} data-delta={pv !== null ? pv : ""} >
            <span className="crypto" data-exchange={x.toLowerCase()}>{x}</span>
            <span>{value}</span>
          </li>
        );
      })}
    </ul>
  </div>
);

// Table 컴포넌트
const Table = ({ data, exchange, flashes }) => (
  <table className="table">
    <thead>
      <tr>
        <th>Symbol</th>
        {exchange.map((ex) => (
          <th key={ex}>
            <span className="crypto" data-exchange={ex.toLowerCase()}>
              {ex}
            </span>
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {Object.entries(data).map(([symbol, item]) => (
        <tr key={symbol}>
          <td>
            <strong className="crypto coin" data-symbol={symbol.toLowerCase()}>
              {symbol}
            </strong>
          </td>
          {Object.entries(item).map(([x, value]) => {
            const flash = flashes.current[`${symbol}-${x}`] || "";
            const delta = flashes.current[`${symbol}-${x}-delta`] || null;
						const cn = delta > 0 ? "positive" : delta < 0 ? "negative" : "neutral";
						const pv = flashes.current[`${symbol}-${x}-prev`] || null;
            return (
              <td key={`${symbol}-${x}`} className={`${cn} ${flash}`} data-delta={pv !== null ? pv : ""} >{value}</td>
            );
          })}
        </tr>
      ))}
    </tbody>
  </table>
);

// Future 메인 컴포넌트
const Future = ({ sidebar }) => {
  const { type } = useParams();

  const [tab, setTab] = useState(type || "usdt");
  const [stylized, stylize] = useState(false);
  const [data, setData] = useState({});
  const [exchange, setExchange] = useState([]);
  const [lastAt, setLastAt] = useState(null);
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(false);
  const api = process.env.REACT_APP_API;

  const dataRef = useRef({});
  const flashes = useRef({});

  const triggerFlash = (symbol, x, value) => {
    flashes.current[`${symbol}-${x}`] = "flash";
    flashes.current[`${symbol}-${x}-prev`] = value; // 이전 값 저장
    setTimeout(() => {
      delete flashes.current[`${symbol}-${x}`];
    }, 100);
  };

  const fetchData = useCallback(
    async (isInitial = false) => {
      try {
        if (isInitial) setLoading(true);

        const response = await axios.get(`${api}/insight/future`, {
          params: { type: tab, limit: limit || undefined },
        });

        const { rate, exchange, at } = response.data;

        if (isInitial) {
          setData(rate || {});
          setExchange(exchange || []);
          Object.entries(rate || {}).forEach(([symbol, items]) => {
            Object.entries(items).forEach(([x, value]) => {
              const cv = value === "-" ? null : parseFloat(value);
              flashes.current[`${symbol}-${x}-prev`] = cv; // 초기 값 저장
            });
          });
        } else {
          Object.entries(rate || {}).forEach(([symbol, items]) => {
            Object.entries(items).forEach(([x, value]) => {
              const pv = flashes.current[`${symbol}-${x}-prev`] || null;
              const cv = value === "-" ? null : parseFloat(value);
              const delta = cv !== null && pv !== null ? cv - pv : null;
              if (delta !== null && delta !== 0) {
                flashes.current[`${symbol}-${x}-delta`] = delta; // delta 저장
                triggerFlash(symbol, x, cv); // delta 계산 후 플래시
              }
            });
          });
        }

        dataRef.current = rate || {};
        setData(rate || {});
        setExchange(exchange || []);
        setLastAt(at);
      } catch (error) {
        console.error("Error fetching funding data:", error);
      } finally {
        if (isInitial) setLoading(false);
      }
    },
    [api, tab, limit]
  );

  useEffect(() => {
    fetchData(true);
    const interval = setInterval(() => {
      fetchData(false);
    }, 5000);
    return () => clearInterval(interval);
  }, [fetchData]);

  useEffect(() => {
    return loadCss("/css/insight.css", () => stylize(true));
  }, []);

  const switchTab = (newTab) => {
    setTab(newTab);
    setLimit(20); // Reset the limit when tab changes
  };

  if (!stylized) return null;

  return (
    <section className={`insight future${sidebar.mobile ? " mobile" : ""}`}>
      <Helmet>
        <title>코글 - 펀딩비율</title>
        <meta
          name="description"
          content="Check the latest funding rates on various exchange."
        />
        <meta
          name="keywords"
          content="future, funding rate, crypto, usdt, token"
        />
      </Helmet>
      <h1>펀딩비율</h1>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link${tab === "usdt" ? " active" : ""}`}
            onClick={() => switchTab("usdt")}
          >
            USDT
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link${tab === "token" ? " active" : ""}`}
            onClick={() => switchTab("token")}
          >
            TOKEN
          </button>
        </li>
        <li className="at">
          <span>Last Updated: </span>
          {lastAt ? new Date(lastAt * 1000).toLocaleString() : "Loading..."}
        </li>
      </ul>

      {sidebar.mobile ? (
        Object.entries(data).map(([symbol, item]) => (
          <Card key={symbol} symbol={symbol} item={item} flashes={flashes} />
        ))
      ) : loading ? (
        <div className="loading">
          <span className="loader">Loading…</span>
        </div>
      ) : (
        <Table data={data} exchange={exchange} flashes={flashes} />
      )}

      {limit === 20 && <More load={() => setLimit(null)} options={{ text: "더 보기" }} />}
    </section>
  );
};

export default Future;

