import React, { useState, useEffect, useRef } from "react";                                                                                                                                                                                                   
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { ago, ellipsis, loadCss } from "../../site";
import useMore from "../../more";
import { More } from "../../component/pagination";

import IconAt from "@mui/icons-material/AccessTime";
import IconFollow from "@mui/icons-material/People";
import IconView from "@mui/icons-material/Visibility";
import IconVideo from "@mui/icons-material/OndemandVideo";
import IconBookmark from "@mui/icons-material/PlaylistAdd";

const Youtube = ({ sidebar }) => {
	const [stylized, stylize] = useState(false);
	const api = process.env.REACT_APP_API;

  const loaded = useRef(false);
  const fetchData = async (page) => {
    const response = await axios.get(`${api}/popular/youtube`, { params: { page } });
    if (response.data.error) {
      throw new Error("데이터 로드 실패");
    }
    return {list: response.data.data, more: response.data.more};
  };

  //const { data, loadMore, hasMore, loading, reset, error, clearError } = useMore(fetchData);
  const { data, loadMore, hasMore, loading, error, clearError } = useMore(fetchData);

  useEffect(() => {
		return loadCss("/css/yt.css",()=>stylize(true));
  }, []);

  useEffect(() => {
    if (!loaded.current) {
      loadMore();
      loaded.current = true;
    }
  }, [loadMore]);

  if (!stylized) return null;

	const List=({e,rank})=>{
		const url=`https://www.youtube.com/${e.endpoint}`;
		const at=ago(e.created_at);
		return (
			<li key={e.endpoint}>
					<article className="hentry card">
						<h3 className="card-header entry-title"><span className="badge rank">{rank+1}</span>
						<a href={url} target="_blank" className="thumbnail yt" rel="noopener noreferrer"><img src={e.thumbnail} alt="" /></a>
						<a href={url} target="_blank" rel="noopener noreferrer">{e.title}</a>
					</h3>
					<div className="card-body">
						<div className="entry-content">
						<div className="thumbnail yt"><a href={url} target="_blank" rel="noopener noreferrer"><img src={e.thumbnail} alt="" /></a></div>
							<div className="description">
								<div><a href={url} target="_blank" rel="noopener noreferrer">{ellipsis(e.description,sidebar.mobile?80:240)}</a></div>
								<ul>
									<li><IconAt /><time dateTime={e.created_at} className="ago">{at}</time></li>
									<li><IconFollow /><span>{e.subscribe.toLocaleString()}</span></li>
									<li><IconView /><span>{e.view.toLocaleString()}</span></li>
									<li><IconVideo /><span>{e.video.toLocaleString()}</span></li>
									<li><button type="button" className="btn bookmark"><IconBookmark /><small>저장</small></button></li>
								</ul>
								{(e.is_on && e.live) &&(
								<div className="live">
									<a href={`https://www.youtube.com/watch?v=${e.live.id}`} target="_blank" rel="noopener noreferrer">
										<small className="badge" data-live="yt">실시간</small>
										<small className="text-muted">{e.live.title} <small className="text-danger">{e.live.view}</small></small>
									</a>
								</div>
							)}
							</div>
						</div>
					</div>
				</article>
				</li>
		);
	};


  return (
    <section className={`popular yt${sidebar.mobile?" mobile":""}`}>
			<Helmet>
        <title>코글 - 코인유튜브</title>
      </Helmet>
			<h1>코인 유튜브</h1>
			{(data?.length > 0) && (<ul>{data.map((e, rank) => (<List key={rank} e={e} rank={rank} />))}</ul>)}
      {error && <More load={()=>false} options={{text:"데이터를 불러오는 중 오류가 발생했습니다."}} />}
      {!loading && hasMore && (<More load={()=>{clearError();loadMore();}} options={{text:"더 보기"}} />)}
      {loading &&  <More load={()=>false} options={{text:"Loading..."}} />}
      {!hasMore && !error && <div>- 끝 -</div>}
    </section>
  );
};

export default Youtube;
