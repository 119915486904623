import { useState } from "react";
import { EditorState, AtomicBlockUtils } from "draft-js";
import "draft-js/dist/Draft.css";
import axios from "axios";

const useDraft = (api, prefix) => {
  const [state, setState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState([]);
  //const [paths, setPaths] = useState([]);

  // 에디터 상태 업데이트
  const update = (state) => {
    setState(state);
  };

  // 미디어 삽입 (이미지/비디오 등)
  const insert = (src, type) => {
    const contentState = state.getCurrentContent();
    const entityType = type.startsWith("image/") ? "IMAGE" : "MEDIA";
		console.log(src);
		console.log(type);

    const contentStateWithEntity = contentState.createEntity(
      entityType,
      "IMMUTABLE",
      { src }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const ed = AtomicBlockUtils.insertAtomicBlock(
      EditorState.set(state, { currentContent: contentStateWithEntity }),
      entityKey,
      " "
    );

    setState(ed);
  };

  // 파일 업로드 처리
  const upload = async (event) => {
    const uploadedFiles = Array.from(event.target.files);
    const newTempFiles = [];

    for (const file of uploadedFiles) {
      const formData = new FormData();
			formData.append("prefix", prefix);
      formData.append("file", file);

      try {
        const response = await axios.post(`${api}/draft/upload`, formData);
        if (response.data.error) {
          alert(`파일 업로드 실패: ${file.name}`);
        } else {
          newTempFiles.push(response.data.path);
          setFiles((prev) => [
            ...prev,
            {
              name: file.name,
              size: file.size,
              path: response.data.path,
              type: file.type,
            },
          ]);
        }
      } catch (error) {
        console.error("파일 업로드 실패:", error);
      }
    }
    //setPaths((prev) => [...prev, ...newTempFiles]);
  };

  // 파일 삭제 처리
  const remove = (index) => {
    const targetFile = files[index];

    // 서버에서 임시 파일 삭제
    axios.post(`${api}/draft/remove`, { path: targetFile.path });

    // 에디터에서 관련 블록 삭제
    const contentState = state.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    const newBlockMap = blockMap.filter((block) => {
      if (block.getType() === "atomic") {
        const entityKey = block.getEntityAt(0);
        if (entityKey) {
          const entity = contentState.getEntity(entityKey);
          const { src } = entity.getData();
          return src !== targetFile.path;
        }
      }
      return true;
    });

    const newContentState = contentState.set("blockMap", newBlockMap);
    const ed = EditorState.push(state, newContentState, "remove-range");
    setState(ed);

    // 로컬 상태 업데이트
    setFiles(files.filter((_, i) => i !== index));
    //setPaths(paths.filter((path) => path !== targetFile.path));
  };

  // 폼 취소 시 임시 파일 정리
  const cancel = async () => {
    if (files.length > 0) {
      try {
        const response = await axios.post(`${api}/draft/cancel`, { files });
        if (response.data.error) {
					console.error("임시 파일 삭제 오류:", response.data.error);
				} else {
					reset();
				}
      } catch (error) {
        console.error("임시 파일 삭제 오류:", error);
      }
    }
  };

  // Media Component for render
  const Media = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src } = entity.getData();
    const type = entity.getType();

    if (type === "IMAGE") {
      return <img src={src} alt="첨부 이미지" style={{ maxWidth: "100%", display: "block" }} />;
    } else if (type === "MEDIA") {
      return (
        <video controls style={{ maxWidth: "100%", display: "block" }}>
          <source src={src} type="video/mp4" />
          브라우저가 동영상을 지원하지 않습니다.
        </video>
      );
    }
    return null;
  };

  // Draft.js의 블록 렌더링 함수
  const render = (contentBlock) => {
    if (contentBlock.getType() === "atomic") {
      return {
        component: Media,
        editable: false,
      };
    }
    return null;
  };

	const reset = () => {
		setFiles([]);
    setState(EditorState.createEmpty());
  };


  return { state, update, insert, files, upload, remove, cancel, reset, render };
};

export default useDraft;
