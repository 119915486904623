import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import reportWebVitals from './reportWebVitals';

import { HelmetProvider as Helmet } from "react-helmet-async";
import { Auth } from "./auth";
import { Mobile } from "./mobile";
import { Theme } from './theme'; 
import { Nav } from "./nav";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<Helmet>
		<Auth>
		<Mobile>
			<Theme>
				<Nav>
					<App />
				</Nav>
			</Theme>
		</Mobile>
		</Auth>
		</Helmet>	
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
