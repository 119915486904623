import React from "react";

export const Pagination = ({ page, total, load, options = {} }) => {
  const tab = options.tab || 2;
  const ellipsis = "…";

  const calculate = () => {
    const left = [];
    const right = [];
    const near = [];

    for (let i = Math.max(page - tab, 1); i <= Math.min(page + tab, total); i++) near.push(i);
    for (let i = 1; i <= Math.min(tab, total); i++) if (!near.includes(i)) left.push(i);
    for (let i = total > tab ? total - tab + 1 : 1; i <= total; i++) if (!near.includes(i)) right.push(i);
    if (left.length && left[left.length - 1] < near[0] - 1) left.push(ellipsis);
    if (right.length && near[near.length - 1] < right[0] - 1) right.unshift(ellipsis);
    return [...left, ...near, ...right];
  };

  const pagination = calculate();
  return (
    <nav>
      <ul className="pagination">
        <li className={`page-item prev${page===1?" disabled":""}`}>
          <button className="page-link" onClick={() => page > 1 && load(page - 1)} disabled={page === 1}>&lt;</button>
        </li>

        {pagination.map((p, index) => (
          <li key={index} className={`page-item${p===page?" active":""}`}>
            {typeof p === "number" ? (
              <button className="page-link" onClick={()=>load(p)} disabled={p===page}>{p}</button>
            ) : (
              <span className="ellipsis">{p}</span>
            )}
          </li>
        ))}

        <li className={`page-item next${page===total?" disabled":""}`}>
          <button className="page-link" onClick={() => page < total && load(page + 1)} disabled={page === total}>&gt;</button>
        </li>
      </ul>
    </nav>
  );
};

export const More = ({ load, options = {} }) => {
  const text = options.text || "더 보기";
  const at = options.at ? `data-at=${options.at}` : "";
  return (
    <div className="pagination more">
      <hr />
      <div>
				<button type="button" onClick={(e) => { e.preventDefault(); load(); }} className="btn btn-light rounded-pill" {...(at && { [at.split("=")[0]]: at.split("=")[1] })} >
					<span>{text}</span>
					<span className="carot"></span>
        </button>
      </div>
    </div>
  );
};
